import { darkGray } from "../../../config/theme";
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { useGetFixtures } from "../../../utils/hooks/useGetFixtures";
import { PATH_PARAMS } from "../../../config/routes";
import { useParams } from "react-router-dom";

export const FixtureOverview = () => {
  const urlParams = useParams();

  const { fixturesData } = useGetFixtures();
  const fixtureData =
    fixturesData &&
    fixturesData.fixtures.find(
      (fixture) => fixture.id === Number(urlParams[PATH_PARAMS.FIXTURES_ID])
    );
  return (
    <Stack>
      {fixtureData?.modified_stats.map((stat) => (
        <Stack key={stat.identifier}>
          <Typography css={gameEventTitleStyle}>{stat.identifier}</Typography>

          <Stack css={gameEventPlayersContainerStyles}>
            <ul css={playerNameStyles}>
              {stat.teamH.map((item) => (
                <li
                  key={item.element}
                >{`${item.epl_player_name} (${item.value})`}</li>
              ))}
            </ul>

            <span css={gameEventPlayersDividerStyles} />

            <ul css={playerNameStyles}>
              {stat.teamA.map((item) => (
                <li
                  key={item.element}
                >{`${item.epl_player_name} (${item.value})`}</li>
              ))}
            </ul>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

const gameEventTitleStyle = css`
  text-transform: capitalize;
  text-align: center;
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  margin-block: 0.5rem;
`;

const gameEventPlayersContainerStyles = css`
  flex-direction: row;
  margin-bottom: 1rem;
`;

const gameEventPlayersDividerStyles = css`
  width: 0.1rem;
  background-color: ${darkGray};
`;

const playerNameStyles = css`
  text-align: center;
  flex: 1;
`;
