import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import {
  HighlightsCard,
  HighlightsCardSkeleton,
  HighlightsImportantKeyCell,
} from "./components";
import { useGetHighlights } from "./hooks/useGetHighlights";
import { useEffect, useState } from "react";
import {
  IHighlightCardDetails,
  IMPORTANT_SUFFIX,
  ISingleHighlightCard,
} from "../../utils/types/types";
import { Box, Stack, Typography } from "@mui/material";
import {
  AppTable,
  AppTableSkeleton,
  AppTableTeamCell,
  PageHeader,
  SelectGameWeekDropDown,
  Skeleton,
} from "../../components";
import { mq } from "../../config/styles";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import { columnNumber } from "../../utils/muiTableColumns";
import { useGetUrl } from "../../utils/hooks/useGetUrl";
import { useNavigateState } from "../../utils/hooks/useNavigateState";
import { useGetHighlightsTabs } from "../../utils/hooks/useGetHighlightsTabs";

/** Renders the single highlights page */
export const SingleHighlightsPage = () => {
  const { categoryId, highlightId } = useParams();
  const { fullHighlightsData, isLoading } = useGetHighlights();
  const [tabsData] = useGetHighlightsTabs();
  const [highlightData, setHighlightData] = useState<ISingleHighlightCard>();
  const [tableColumn, setTableColumn] = useState<
    GridColDef<IHighlightCardDetails>[]
  >([]);
  const navigate = useNavigateState();
  const { getTeamViewPath } = useGetUrl();

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(getTeamViewPath(params.row.entry));
  };

  useEffect(() => {
    if (fullHighlightsData && tabsData) {
      const highlightsData = fullHighlightsData.find(
        (highlight) =>
          highlight.highlight_id === highlightId &&
          highlight.category_id === categoryId
      );

      const tabData = tabsData.find(
        (tab) => tab.category_id === highlightsData?.category_id
      );

      if (highlightsData && tabData) {
        setHighlightData({ ...highlightsData, ...tabData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullHighlightsData, tabsData]);

  useEffect(() => {
    if (highlightData) {
      const extraColumns: GridColDef<IHighlightCardDetails>[] =
        highlightData.important_properties.map((imp) => ({
          field: imp.key,
          headerName: `${imp.display_name} ${IMPORTANT_SUFFIX[imp.type]}`,
          flex: 1.5,
          align: "center",
          headerAlign: "center",
          renderCell: (value) => (
            <HighlightsImportantKeyCell
              title={value.row[imp.key].toLocaleString("en-US")}
              accentColor={
                imp.color === "primary" ? highlightData.accent_color! : ""
              }
            />
          ),
        }));

      const teamColumn: GridColDef<IHighlightCardDetails> = {
        field: "team",
        headerName: "Team",
        flex: 1.5,
        sortable: false,
        renderCell: (value) => (
          <AppTableTeamCell
            mangerName={value.row.entry_name}
            teamName={value.row.player_name}
            accentColor={highlightData.accent_color}
          />
        ),
      };

      setTableColumn([columnNumber, teamColumn, ...extraColumns]);
    }
  }, [highlightData]);

  return (
    <div>
      <Helmet>
        <title>{highlightData?.display_name}</title>
      </Helmet>

      <Stack gap={3}>
        {
          <>
            {highlightData ? (
              <>
                <PageHeader title={highlightData.display_name} />
                <Typography>{highlightData?.description}</Typography>
              </>
            ) : (
              <>
                <Skeleton sx={{ fontSize: "1.5rem" }} />
                <Skeleton sx={{ fontSize: "0.85rem" }} />
                <Skeleton sx={{ fontSize: "0.85rem" }} width={"45%"} />
              </>
            )}
          </>
        }

        <SelectGameWeekDropDown />

        <>
          {highlightData && !isLoading ? (
            <Box>
              <HighlightsCard
                navigateOnMangerNameClick
                cardStyles={singleHighlightCardStyle}
                accentColor={highlightData.accent_color}
                chipImageURL={highlightData.category_icon}
                importantProperties={highlightData.important_properties}
                highlightCardData={highlightData}
              />
              <AppTable
                onRowClick={handleRowClick}
                accentColor={highlightData.accent_color}
                columns={tableColumn}
                rows={
                  highlightData.view_from === "top"
                    ? highlightData.details
                    : highlightData.details.slice().reverse()
                }
              />
            </Box>
          ) : (
            <Box>
              <HighlightsCardSkeleton extraStyles={singleHighlightCardStyle} />
              <AppTableSkeleton />
            </Box>
          )}
        </>
      </Stack>
    </div>
  );
};

const singleHighlightCardStyle = css`
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;

  height: auto !important;

  ${mq["sm"]} {
    height: 15rem !important;
  }

  ${mq["md"]} {
    height: auto !important;
  }

  ${mq["lg"]} {
    height: 18rem !important;
  }

  ${mq["xl"]} {
    height: 20.5rem !important;
  }
`;
