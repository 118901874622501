import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { IFixturesResponse } from "../../../utils/hooks/useGetFixtures";
import { FIXTURES_RESULTS } from "../../../constants/fixtures-pages.constants";
import { secondaryColor, white } from "../../../config/theme";
import { Skeleton } from "../../../components";

interface IFixtureResultGameScore {
  fixtureData: IFixturesResponse["fixtures"][0] | undefined;
}

/** Renders the fixture score and time left for the game in the fixture results page */
export const FixtureResultGameScore = ({
  fixtureData,
}: IFixtureResultGameScore) => {
  return (
    <Stack css={teamsInfoContainersStyles}>
      <Stack css={teamsInfoStyles}>
        <TeamInfo
          img={fixtureData && fixtureData.team_h_logo}
          teamName={`${fixtureData && fixtureData.team_h_name} ${
            FIXTURES_RESULTS.HOME_TEAM
          }`}
        />
      </Stack>

      {fixtureData && fixtureData.started ? (
        <Stack css={gameResultsStyles}>
          <Typography css={gameScoreStyles}>
            <span>{fixtureData.team_h_score || "0"}</span>
            <span>-</span>
            <span>{fixtureData.team_a_score || "0"}</span>
          </Typography>

          {fixtureData.finished ? (
            <Typography css={fullTimeStyles}>
              {FIXTURES_RESULTS.FULL_TIME}
            </Typography>
          ) : (
            <Typography css={gameMinuteStyles}>
              {fixtureData.minutes}
            </Typography>
          )}
        </Stack>
      ) : (
        <Typography css={[gameScoreStyles, !fixtureData && loadingStyles]}>
          {FIXTURES_RESULTS.VS}
        </Typography>
      )}

      <Stack css={teamsInfoStyles}>
        <TeamInfo
          img={fixtureData && fixtureData.team_a_logo}
          teamName={`${fixtureData && fixtureData.team_a_name} ${
            FIXTURES_RESULTS.AWAY_TEAM
          }`}
        />
      </Stack>
    </Stack>
  );
};

const TeamInfo = (props: {
  img: string | undefined;
  teamName: string | undefined;
}) => {
  if (!props.img)
    return (
      <>
        <Skeleton
          sx={{
            maxWidth: "clamp(2.2rem, 14vw, 5rem)",
            aspectRatio: "1/1",
            borderRadius: "9999px",
            marginBottom: "1rem",
          }}
        />
        <Skeleton width={"10rem"} />
      </>
    );

  return (
    <>
      <img src={props.img} />
      <Typography>{props.teamName}</Typography>
    </>
  );
};

const teamsInfoContainersStyles = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > * {
    flex: 1;
  }
`;

const teamsInfoStyles = css`
  align-items: center;
  justify-content: space-between;

  img {
    width: clamp(2.2rem, 14vw, 5rem);
    aspect-ratio: 1/1;
    margin-bottom: 0.5rem;
  }
`;

const gameResultsStyles = css`
  align-items: center;
`;

const gameScoreStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
`;

const fullTimeStyles = css`
  font-weight: 800;
  font-size: clamp(1rem, 2.5vw, 1.3rem);
`;

const gameMinuteStyles = css`
  font-weight: 700;
  padding: 0.2rem;
  border-radius: 9999999px;
  background-color: ${secondaryColor};
  color: ${white};
  width: 3.5rem;
  text-align: center;
`;

const loadingStyles = css`
  opacity: 0.3;
`;
