import { Box, css, Stack, Typography } from "@mui/material";
import {
  customScrollbar,
  homeCardBgColor,
  title,
} from "../../../config/styles";
import { Link, Skeleton } from "../../../components";
import { primaryColor, secondaryColor } from "../../../config/theme";
import { useGetFixtures } from "../../../utils/hooks/useGetFixtures";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { HOME_PAGE } from "../../../constants/home-page.constants";
import {
  getTimeLeft,
  getTimeLeftForGame,
  isToday,
} from "../../../utils/dateHandlers";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";

/** Renders the fixture summary section in the home page */
export const FixturesSummary = () => {
  const { fixturesData } = useGetFixtures({ isCurrent: true });
  const [searchParams] = useSearchParams();
  const { getFixturesPath, getFixtureResultsPath } = useGetUrl();
  const navigate = useNavigateState();

  const handleFixtureClick = (fixtureId: string | number) => {
    navigate(getFixtureResultsPath(fixtureId));
  };

  return (
    <Stack css={rootStyle}>
      <Stack css={tableTitleContainerStyle}>
        <Typography css={tableTitleStyle}>
          {HOME_PAGE.FIXTURES_SUMMARY.TITLE}{" "}
          {searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)}
        </Typography>
        <Link to={getFixturesPath()}>
          {HOME_PAGE.FIXTURES_SUMMARY.LINK_TEXT}
        </Link>
      </Stack>

      <Box css={fixturesGroupContainerStyles}>
        {fixturesData
          ? fixturesData.fixtures.map((fixture, index) => (
              <Stack
                key={index}
                css={fixtureContainerStyles}
                onClick={() => handleFixtureClick(fixture.id)}
              >
                <Stack css={teamIconStyles}>
                  <img src={fixture.team_h_logo} />
                  <img src={fixture.team_a_logo} />
                </Stack>

                <Stack css={teamNameStyles}>
                  <Typography variant="h1">{`${fixture.team_h_name} vs ${fixture.team_a_name}`}</Typography>
                  {fixture.started ? (
                    <Typography css={fixtureScoreStyles}>
                      <span>{fixture.team_h_score}</span>
                      <span>-</span>
                      <span>{fixture.team_a_score}</span>
                    </Typography>
                  ) : (
                    <Typography>{getTimeLeft(fixture.kickoff_time)}</Typography>
                  )}
                </Stack>

                {!fixture.started &&
                  !fixture.finished &&
                  !isToday(fixture.kickoff_time) && (
                    <Typography
                      sx={{ background: "#222232" }}
                      css={fixtureTimeStyle}
                    >
                      {getTimeLeftForGame(fixture.kickoff_time).gameDayDate}
                    </Typography>
                  )}

                {!fixture.started &&
                  !fixture.finished &&
                  isToday(fixture.kickoff_time) && (
                    <Typography
                      sx={{ background: "#222232" }}
                      css={fixtureTimeStyle}
                    >
                      {HOME_PAGE.FIXTURES_SUMMARY.TODAY} <br />
                      {getTimeLeftForGame(fixture.kickoff_time).kickOffTime}
                    </Typography>
                  )}

                {fixture.started && !fixture.finished && (
                  <Typography
                    sx={{ background: primaryColor }}
                    css={fixtureTimeStyle}
                  >
                    {`${fixture.minutes}’`}
                  </Typography>
                )}

                {fixture.started && fixture.finished && (
                  <Typography
                    sx={{ background: secondaryColor }}
                    css={fixtureTimeStyle}
                  >
                    {HOME_PAGE.FIXTURES_SUMMARY.FULL_TIME}
                  </Typography>
                )}
              </Stack>
            ))
          : Array.from({ length: 5 }, (_, index) => (
              <SingleFixtureSummarySkeleton key={index} />
            ))}
      </Box>
    </Stack>
  );
};

const SingleFixtureSummarySkeleton = () => {
  return (
    <Stack css={fixtureContainerStyles}>
      <Stack css={teamIconStyles}>
        <Skeleton component={"span"} />
        <Skeleton component={"span"} />
      </Stack>

      <Stack css={teamNameStyles}>
        <Skeleton />
      </Stack>
      <Typography
        sx={{ background: "#222232" }}
        css={fixtureTimeStyle}
      ></Typography>
    </Stack>
  );
};

const rootStyle = css`
  ${homeCardBgColor}
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;
`;

const tableTitleStyle = css`
  ${title}
`;

const tableTitleContainerStyle = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: underline;
    font-weight: 200;
    color: white;
  }
`;

const fixturesGroupContainerStyles = css`
  ${customScrollbar}
  overflow: auto;
  height: 50vh;
`;

const teamIconStyles = css`
  --size: 2.5rem;
  flex-direction: row;
  position: relative;
  height: 100%;
  width: calc(var(--size) * 2);
  z-index: 1;
  img,
  span {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    width: var(--size);
    aspect-ratio: 1/1;
    border: calc(var(--size) * 0.15) solid #222232;
    border-radius: 99999px;
    box-shadow: 0 0 0 calc(var(--size) * 0.1) #191920;
  }

  img:nth-of-type(1),
  span:nth-of-type(1) {
    left: 1rem;
  }

  img:nth-of-type(2),
  span:nth-of-type(2) {
    left: calc(1rem + var(--size) - 0.15 * var(--size));
    z-index: -1;
  }
`;

const fixtureContainerStyles = css`
  margin-bottom: 0.7rem;
  margin-right: 0.5rem;
  background: #191920;
  border-radius: 1rem;
  flex-direction: row;
  height: 4rem;
  overflow: hidden;
  gap: 1rem;
  cursor: pointer;
`;

const teamNameStyles = css`
  flex: 3;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 1rem;
    text-align: center;
  }
`;

const fixtureScoreStyles = css`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const fixtureTimeStyle = css`
  flex: 1.2;
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
