import { Box, Grid, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import {
  bgColor,
  bgColorDark,
  black,
  secondaryColor,
  tableHeaderBgColor,
  white,
} from "../config/theme";
import captainsIcon from "../assets/mini-league/league-captains-icon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IFixturesResponse } from "../utils/hooks/useGetFixtures";
import { useGlobalInfoContext } from "../context/GlobalInfoContext";
import { AppTooltip, Skeleton } from ".";
import { getTimeLeftForGame } from "../utils/dateHandlers";
import { FIXTURE_PAGE } from "../constants/fixtures-pages.constants";
import { useSearchParams } from "react-router-dom";
import { QUERY_PARAMS } from "../config/routes";

const GAME_IMPORTANCE_COLOR: { [key: string]: string } = {
  crucial: "red",
  important: "orange",
  normal: "green",
  insignificant: "grey",
};

type fixtureType = IFixturesResponse["fixtures"][0];

interface IFixtureCard extends fixtureType {
  onFixtureCardClick?(): void;
}

/** Renders the fixture card used in fixtures page */
export const FixtureCard = ({ onFixtureCardClick, ...props }: IFixtureCard) => {
  const [searchParams] = useSearchParams();
  const { timeLeft, kickOffTime, gameDayDate } = getTimeLeftForGame(
    props.kickoff_time
  );
  const { selectedLeague, liveGameWeek } = useGlobalInfoContext();

  return (
    <Stack css={fixtureCardContainerStyles} onClick={onFixtureCardClick}>
      <Stack css={topSectionContainerStyles}>
        {searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK)! <=
          String(liveGameWeek) && (
          <FixtureCardCapChip
            title={FIXTURE_PAGE.FIXTURE_CARD.CAPTAINS_CHIP(
              props.captains_count
            )}
            toolTipTitle={FIXTURE_PAGE.FIXTURE_CARD.TOOLTIP_TEXT(
              props.captains_count,
              selectedLeague?.name || ""
            )}
          />
        )}

        {!props.finished && (
          <Stack css={fixturesTimeStyles(props.started)}>
            {props.started && !props.finished
              ? `${props.minutes}''`
              : kickOffTime}
          </Stack>
        )}
      </Stack>

      {/* MiddleSection */}
      <Grid container css={middleSectionContainerStyles}>
        <Grid item xs>
          <Typography css={[teamNameStyles, homeTeamNameStyles]}>
            {props.team_h_name}
          </Typography>
        </Grid>
        <Grid item>
          <img src={props.team_h_logo} />
        </Grid>
        <Grid item xs>
          {props.started ? (
            <Typography css={liveScoresStyle}>
              <span>{props.team_h_score}</span>
              <span>:</span>
              <span>{props.team_a_score}</span>
            </Typography>
          ) : (
            <Typography css={timeLeftStyles}>
              <span className="date">{gameDayDate}</span>
              <span>{`${timeLeft} left`}</span>
            </Typography>
          )}
        </Grid>
        <Grid item>
          <img src={props.team_a_logo} />
        </Grid>
        <Grid item xs>
          <Typography css={teamNameStyles}>{props.team_a_name}</Typography>
        </Grid>
      </Grid>

      {/* Bottom Section */}
      <Stack
        css={bottomSectionContainerStyles(
          GAME_IMPORTANCE_COLOR[props.game_importance]
        )}
      >
        <Typography>
          <FiberManualRecordIcon /> {props.game_importance}
        </Typography>
        <Typography variant="h1">
          {FIXTURE_PAGE.FIXTURE_CARD.INFO(
            props.my_players_count,
            selectedLeague?.name || "",
            props.league_players_count
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

interface IFixtureCardCapChip {
  toolTipTitle: string;
  title: string;
}

/** Renders the captains ownership count in the fixture cards */
const FixtureCardCapChip = (props: IFixtureCardCapChip) => {
  return (
    <AppTooltip title={props.toolTipTitle}>
      <Stack onClick={(e) => e.stopPropagation()} css={fixturesCardChip}>
        <img src={captainsIcon} />
        <p>{props.title}</p>
      </Stack>
    </AppTooltip>
  );
};

/** Renders the loading skeleton of the fixture card */
export const FixtureCardSkeleton = () => {
  return (
    <Stack css={fixtureCardContainerStyles}>
      <Box>
        <Skeleton
          width={"50%"}
          sx={{ height: "1.5rem", borderRadius: "9999px", margin: "1rem" }}
        />
      </Box>

      <Stack
        sx={{
          flex: "1",
          flexDirection: "row",
          color: bgColor,
          width: "100%",
          maxWidth: "9rem",
          marginInline: "auto !important",
          fontSize: "clamp(4rem, 10vw, 6rem)",
          fontWeight: "700",
          justifyContent: "space-between",
        }}
      >
        <span>0</span>
        <span>:</span>
        <span>0</span>
      </Stack>

      <Stack sx={{ backgroundColor: bgColorDark, padding: "1rem" }}>
        <Skeleton sx={{ marginBottom: "0.5rem" }} width={"30%"} />
        <Skeleton sx={{ marginBottom: "0.3rem" }} />
      </Stack>
    </Stack>
  );
};

const fixtureCardContainerStyles = css`
  height: clamp(14rem, 30vw, 17rem);
  background: linear-gradient(105deg, #1f1f35 50%, ${tableHeaderBgColor} 50%);
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;

  P {
    margin: 0;
  }
`;

const topSectionContainerStyles = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
`;

const fixturesCardChip = css`
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  background-color: #63636310;
  padding: 0.2rem 0.5rem;
  border-radius: 9999px;
`;

const fixturesTimeStyles = (didStart: Boolean) => css`
  background-color: ${white};
  color: ${black};
  font-weight: 700;
  padding: 0.3rem 0.5rem;
  border-radius: 9999999px;
  margin-left: auto;

  ${didStart &&
  css`
    background-color: ${secondaryColor};
    color: ${white};
  `}
`;

const middleSectionContainerStyles = css`
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  img {
    width: clamp(2rem, 12vw, 5rem);
    aspect-ratio: 1/1;
  }
`;

const teamNameStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  font-size: clamp(0.875rem, 2.5vw, 1.5rem);
`;
const homeTeamNameStyles = css`
  text-align: right;
`;

const liveScoresStyle = css`
  font-weight: 900;
  font-size: clamp(2rem, 6vw, 3.5rem);
  white-space: nowrap;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
`;

const timeLeftStyles = css`
  font-weight: 900;
  font-size: clamp(1.2rem, 6vw, 2.1rem);
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .date {
    font-weight: 400;
    font-size: clamp(0.75rem, 2vw, 0.875rem);
  }
`;

const bottomSectionContainerStyles = (importanceColor: string) => css`
  background-color: white;
  padding: 0.5rem 1rem;
  color: ${black};

  p {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    text-transform: capitalize;

    svg {
      color: ${importanceColor};
      font-size: 1rem;
    }
  }

  h1 {
    font-size: clamp(0.875rem, 2.5vw, 1rem);
  }
`;
