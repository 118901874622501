import * as yup from "yup";
import { AUTH_ERROR_MESSAGES } from "../../constants/auth-pages.constants";

const name = yup.string().required(AUTH_ERROR_MESSAGES.NAME.REQUIRED);
const email = yup
  .string()
  .email(AUTH_ERROR_MESSAGES.EMAIL.INVALID_FORMAT)
  .required(AUTH_ERROR_MESSAGES.EMAIL.REQUIRED);

const password = yup
  .string()
  .min(8, AUTH_ERROR_MESSAGES.PASSWORD.MIN_LENGTH)
  .max(72, AUTH_ERROR_MESSAGES.PASSWORD.MAX_LENGTH)
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])/,
    AUTH_ERROR_MESSAGES.PASSWORD.COMPLEX_PASSWORD
  )
  .required(AUTH_ERROR_MESSAGES.PASSWORD.REQUIRED);

const confirm_password = yup
  .string()
  .oneOf([yup.ref("password")], AUTH_ERROR_MESSAGES.CONFIRM_PASSWORD.NOT_MATCH)
  .required(AUTH_ERROR_MESSAGES.CONFIRM_PASSWORD.REQUIRED);

const policy = yup
  .boolean()
  .oneOf([true], AUTH_ERROR_MESSAGES.POLICY.CHECKED)
  .required();

/* YUP Schemas */
export const RegisterYupSchema = yup.object().shape({
  name,
  email,
  password,
  confirm_password,
  policy,
});
export type RegisterFormType = yup.InferType<typeof RegisterYupSchema>;

export const SignInYupSchema = yup.object().shape({
  email,
  password: yup.string().required(AUTH_ERROR_MESSAGES.PASSWORD.REQUIRED),
});
export type SignInFormType = yup.InferType<typeof SignInYupSchema>;

export const TeamDetailsYupSchema = yup.object().shape({
  team_id: yup.string().required(AUTH_ERROR_MESSAGES.TEAM_ID.REQUIRED),
});
export type TeamDetailsType = yup.InferType<typeof TeamDetailsYupSchema>;

export const forgotPasswordYupSchema = yup.object().shape({
  email,
});
export type forgotPasswordFormType = yup.InferType<
  typeof forgotPasswordYupSchema
>;

export const ResetPasswordYupSchema = yup.object().shape({
  password,
  confirm_password,
});
export type ResetPasswordPasswordFormType = yup.InferType<
  typeof ResetPasswordYupSchema
>;
