import { Provider } from "@supabase/gotrue-js";
import { Button } from "../../../components";
import { supabase } from "../../../config/supabase";
import { ROUTES } from "../../../config/routes";
import { useState } from "react";
import { css } from "@emotion/react";
import { animatePulse } from "../../../config/styles";
import { SESSION_STORAGE } from "../../../config/app.config";
import { useLocation } from "react-router-dom";

interface IOAuthButton {
  provider: Provider;
  children: React.ReactNode | string;
}

/** OAuth button that signing in the user with oAuth and redirect to the team id page */
export const OAuthButton = ({ provider, children }: IOAuthButton) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const handleOAuthButton = async () => {
    setIsLoading(true);

    if (location.state && location.state.from) {
      sessionStorage.setItem(
        SESSION_STORAGE.URL_HISTORY,
        location.state.from.pathname + location.state.from.search
      );
    }

    const { error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: `${window.location.origin}${ROUTES.TEAM_ID_PAGE}`,
      },
    });

    if (error) {
      throw error;
    }
    setIsLoading(false);
  };

  return (
    <Button
      css={isLoading ? loadingStyle : undefined}
      onClick={handleOAuthButton}
      button="google"
      disabled={isLoading}
    >
      {children}
    </Button>
  );
};

const loadingStyle = css`
  ${animatePulse}
  background-color: white  !important;
`;
