import { GridColDef } from "@mui/x-data-grid";
import { IMiniLeagueStandingsCaptainHistoryRes } from "../hooks/useGetMiniLeagueStandingsTableHistory";
import { AppTableExpandCell, AppTableTeamCell } from "../../components";
import { IMiniLeagueCaptainsTableRes } from "../hooks/useGetMiniLeagueCaptainTable";
import { IMiniLeagueMostHurtfulRes } from "../hooks/useGetMiniLeagueMostHurtful";
import { IMiniLeagueStanding } from "../hooks/useGetMiniLeagueData";

/**
 * adds the gameWeek captains field in the standing object and return an array of the combined objects
 * used to render the standings table when history is true (past gw for fplMangers)
 */
export const getStandingsTableHistoryRows = (
  standings: IMiniLeagueStanding[],
  miniLeagueCaptainsHistory: IMiniLeagueStandingsCaptainHistoryRes[]
) => {
  const rows = standings.map((item, index) => ({
    ...item,
    gameWeekCaptains: miniLeagueCaptainsHistory[index].gameWeekCaptains,
  }));

  return rows;
};

export const teamColumn: GridColDef<IMiniLeagueStanding> = {
  field: "team",
  headerName: "Team",
  sortable: false,
  flex: 1,
  minWidth: 100,
  renderCell: (value) => (
    <AppTableTeamCell
      mangerName={value.row.entry_name}
      teamName={value.row.player_name}
    />
  ),
};

export const extraColumns: GridColDef<IMiniLeagueStanding>[] = [
  {
    field: "event_total",
    headerName: "GW",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "total",
    headerName: "Pts",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    sortable: false,
  },
];

export const liveGWCaptainPointsColumn: GridColDef<IMiniLeagueStanding> = {
  field: "event",
  headerName: "C.pts",
  align: "center",
  minWidth: 120,
  headerAlign: "center",
  sortable: false,
  renderCell: (value) => `${value.row.captain_name} (${value.row.captain_pts})`,
};

export const getGameWeekColumn = (
  gameWeekCaptains: IMiniLeagueStandingsCaptainHistoryRes["gameWeekCaptains"]
): GridColDef<IMiniLeagueStandingsCaptainHistoryRes>[] => {
  const captainsArray: GridColDef<IMiniLeagueStandingsCaptainHistoryRes>[] =
    Object.entries(gameWeekCaptains).map(([key, value]) => ({
      field: String(key),
      headerName: `GW ${value.gameWeek}`,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (value) =>
        value.row.gameWeekCaptains && value.row.gameWeekCaptains[key]
          ? `${value.row.gameWeekCaptains[key].captain_name} (${value.row.gameWeekCaptains[key].gw_total_points})`
          : "",
    }));

  return captainsArray;
};

/******* Captains Table Columns *******/

export const countColumn: GridColDef<IMiniLeagueCaptainsTableRes> = {
  field: "count",
  headerName: "Captained by count",
  align: "center",
  headerAlign: "center",
  minWidth: 60,
  flex: 1,
  sortable: false,
};

export const captainColumn: GridColDef<IMiniLeagueCaptainsTableRes> = {
  field: "team",
  headerName: "Name",
  flex: 1,
  minWidth: 80,
  sortable: false,
  renderCell: (value) => (
    <AppTableTeamCell
      mangerName={value.row.name.web_name}
      teamName={value.row.team.name}
    />
  ),
};

export const getCaptainGwPoints = (
  gwHistoryPoints: IMiniLeagueCaptainsTableRes["gwHistoryPoints"]
): GridColDef<IMiniLeagueCaptainsTableRes>[] => {
  return Object.keys(gwHistoryPoints).map((key) => ({
    field: key,
    headerName: `${key} Pts`,
    flex: 1,
    minWidth: 60,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: (value) =>
      value.row.gwHistoryPoints && value.row.gwHistoryPoints[key] !== null
        ? value.row.gwHistoryPoints[key]
        : "No Data",
  }));
};

/******* Most Hurtful Table Columns *******/

export const hurtfulPlayer: GridColDef<IMiniLeagueMostHurtfulRes> = {
  field: "team",
  headerName: "Name",
  flex: 1,
  sortable: false,
  renderCell: (value) => (
    <AppTableTeamCell
      mangerName={value.row.name.web_name}
      teamName={value.row.team.name}
    />
  ),
};

export const arrowColumn: GridColDef = {
  field: "arrow",
  headerName: "Managers that own him",
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
  renderCell: AppTableExpandCell,
};

export const OwnershipColumn: GridColDef<IMiniLeagueMostHurtfulRes> = {
  field: "ownedPercentage",
  headerName: "Ownership in the league",
  flex: 1,
  align: "center",
  headerAlign: "center",
  sortable: false,
};
