import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./routes";
import { AuthLayout, MainLayout } from "../pages/layouts";
import {
  ComingSoonPage,
  ForgotPasswordPage,
  HighlightsPage,
  IntroView,
  PrivacyPage,
  RegisterPage,
  ResetPasswordPage,
  SignInPage,
  SingleHighlightsPage,
  TeamDetailsPage,
  TermsOfServicePage,
  ViewTeamPage,
  MiniLeagueLayout,
  MiniLeagueStandingPage,
  MiniLeagueCaptainsPage,
  MiniLeagueMostHurtfulPage,
  HomePage,
  FixturesPage,
  MiniLeagueNewEntriesPage,
  FixtureResultPage,
} from "../pages";
import { UserContextProvider } from "../context/UserContext";
import { AuthPageGuard, ProtectedRoute } from "../components";
import { DebugRouter } from "../components/DebugRouter";
import { GlobalInfoContextProvider } from "../context/GlobalInfoContext";
import { NavigateToHomePage } from "../utils/hooks/NavigateToHomePage";
import { ErrorBoundary } from "../components";

export const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <DebugRouter>
          <GlobalInfoContextProvider>
            <UserContextProvider />
          </GlobalInfoContextProvider>
        </DebugRouter>
      </ErrorBoundary>
    ),
    errorElement: <p>404 Page</p>,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            path: "/",
            element: (
              <ProtectedRoute>
                <NavigateToHomePage />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTES.HOME_PATH,
            element: (
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            ),
          },
          ...[ROUTES.HIGHLIGHTS_PATH, ROUTES.HIGHLIGHTS_CATEGORY_PATH].map(
            (route) => ({
              path: route,
              element: (
                <ProtectedRoute>
                  <HighlightsPage />
                </ProtectedRoute>
              ),
            })
          ),
          {
            path: ROUTES.SINGLE_HIGHLIGHTS_PATH,
            element: (
              <ProtectedRoute>
                <SingleHighlightsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTES.FIXTURES_PATH,
            element: (
              <ProtectedRoute>
                <FixturesPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTES.FIXTURE_RESULTS_PATH,
            element: (
              <ProtectedRoute>
                <FixtureResultPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ROUTES.TRANSFERS_PATH,
            element: (
              <ProtectedRoute>
                <ComingSoonPage />
              </ProtectedRoute>
            ),
          },
          ...[ROUTES.MY_TEAM_PAGE, ROUTES.TEAM_PAGE].map((route) => ({
            path: route,
            element: (
              <ProtectedRoute>
                <ViewTeamPage />
              </ProtectedRoute>
            ),
          })),
          {
            path: ROUTES.PRIVACY_POLICY_PAGE,
            element: <PrivacyPage />,
          },
          {
            path: ROUTES.TERMS_OF_SERVES_PAGE,
            element: <TermsOfServicePage />,
          },
          {
            element: (
              <ProtectedRoute>
                <MiniLeagueLayout />
              </ProtectedRoute>
            ),
            children: [
              {
                path: ROUTES.MINI_LEAGUE_STANDINGS_PAGE,
                element: <MiniLeagueStandingPage />,
              },
              {
                path: ROUTES.MINI_LEAGUE_CAPTAINS_PAGE,
                element: <MiniLeagueCaptainsPage />,
              },
              {
                path: ROUTES.MINI_LEAGUE_MOST_HURTFUL_PAGE,
                element: <MiniLeagueMostHurtfulPage />,
              },
              {
                path: ROUTES.MINI_LEAGUE_NEW_ENTRIES_PAGE,
                element: <MiniLeagueNewEntriesPage />,
              },
            ],
          },
        ],
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: ROUTES.INTRO_PAGE,
            element: (
              <AuthPageGuard>
                <IntroView />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.REGISTER_PAGE,
            element: (
              <AuthPageGuard>
                <RegisterPage />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.SIGN_IN_PAGE,
            element: (
              <AuthPageGuard>
                <SignInPage />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.TEAM_ID_PAGE,
            element: <TeamDetailsPage />,
          },
          {
            path: ROUTES.FORGOT_PASSWORD_PAGE,
            element: (
              <AuthPageGuard>
                <ForgotPasswordPage />
              </AuthPageGuard>
            ),
          },
          {
            path: ROUTES.RESET_PASSWORD_PAGE,
            element: <ResetPasswordPage />,
          },
        ],
      },
    ],
  },
]);
