export const SOCIAL_MEDIA_LINKS = {
  TWITTER: "https://x.com/champfpl?s=21",
  REDDIT: "https://www.reddit.com/u/FPL-Champ/s/tpNGLYn8X1",
};

export const ERROR_MODAL_CONTENT = {
  TITLE: "Oh Snap!",
  CLOSE_BUTTON_TITLE: "Close",
};

export const LOADING_SCREEN_CONTENT = {
  TITLE: "FPL CHAMPS",
};

export const FALLBACK_UI = {
  TITLE: "Something went wrong, we apologize for that, we'll work on fixing it",
  BTN_TEXT: "Go to Home Page",
};

export const MODAL_ERROR_MESSAGES = {
  SELECT_LEAGUE_WITH_MORE_THAN_500_ENTRY:
    "We currently only support classic leagues up to 500 entries.",
  GAME_WEEK_IS_UPDATING: "The game is being updated.",
  LOGOUT: {
    TITLE:
      "Would you like to change your team or to log out from your account?",
    LOGOUT_BUTTON: "Log out",
    CHANGE_TEAM_BUTTON: "Change Team",
  },
};

export const SNACKBAR_MESSAGES = {
  REGISTER_FIRST_TEAM_DETAILS: "Please register first then add team Id",
  RESET_PASSWORD_EMAIL_SENT: "Email sent to reset your password",
  CONFIRMATION_EMAIL_SENT: "Check your email for a confirmation mail",
  PASSWORD_RESET: "Password reset successfully",
  HIGHLIGHTS_LOADING_8_SEC:
    "Hang on while we do our magic, this shouldn’t be long now",
  HIGHLIGHTS_LOADING_12_SEC: "Almost there now...",
};

export const GAME_WEEK_DROPDOWN_CONTENT = {
  LOADING_TEXT: "Loading Game week",
  DROPDOWN_ITEM_TEXT: (gameWeekNumber: number, isCurrent: boolean) =>
    `Game week ${gameWeekNumber} ${isCurrent ? "(Current)" : ""}`,
};

export const SELECT_LEAGUE_DROPDOWN_CONTENT = {
  LOADING_TEXT: "Loading your league",
};

export const PHASE_DROPDOWN = {
  TITLE: "Select Phase: ",
  LOADING_TEXT: "Loading Phases",
};

export const SIDE_NAVIGATION_BAR_TITLES = {
  MAIN_NAVIGATION_TITLES: {
    HOME: "Home",
    HIGHLIGHTS: "Highlights",
    LEAGUES: "Leagues",
    TRANSFERS: "Transfers",
    POINTS: "Points",
    FIXTURES: "Fixtures",
  },

  SOCIAL_MEDIA_TITLE: {
    REDDIT: "Follow us on Reddit",
    TWITTER: "Follow us on X",
  },

  LOGOUT: "Logout",
} as const;

export const FOOTER = {
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_OF_SERVICE: "Terms of service",
};
