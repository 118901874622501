import { GridColDef } from "@mui/x-data-grid";
import { IPlayerOwnershipFixture } from "../hooks/useGetPlayerOwnershipFixture";
import { Box } from "@mui/material";

export const playerOwnershipColumns: GridColDef<
  IPlayerOwnershipFixture["team_a_player"][0]
>[] = [
  {
    field: "web_name",
    headerName: "Player",
    flex: 1.5,
    sortable: false,
    renderCell: ({ row }) => (
      <Box
        sx={{
          fontWeight: 600,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {row.name}
      </Box>
    ),
  },
  {
    field: "gameWeekPoints",
    headerName: "GW Pts",
    flex: 1,
    align: "center",
    headerAlign: "center",
    sortable: false,
  },
  {
    field: "picked_count",
    headerName: "Owned by",
    flex: 1,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: ({ row }) => (
      <Box
        sx={{
          textDecoration: row.picked_count > 0 ? "underline" : undefined,
        }}
      >
        {row.picked_count}
      </Box>
    ),
  },
  {
    field: "captain_count",
    headerName: "Captained By",
    flex: 1,
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: ({ row }) => (
      <Box
        sx={{
          textDecoration: row.captain_count > 0 ? "underline" : undefined,
        }}
      >
        {row.captain_count}
      </Box>
    ),
  },
];
