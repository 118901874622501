import { Tooltip } from "@mui/material";
import { css } from "@emotion/react";
import React from "react";

/** Implements the tool tip used in the app with default settings and styles */
export const AppTooltip = (props: {
  children: React.ReactElement;
  title: string;
}) => {
  return (
    <Tooltip
      arrow
      enterTouchDelay={1} // disable long press on mobile i.e. display onClick
      disableFocusListener // touch and hover
      leaveTouchDelay={1000}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
      PopperProps={{
        sx: {
          padding: "0.7rem",
        },
      }}
      title={props.title}
      css={tooltipStyles}
    >
      {props.children}
    </Tooltip>
  );
};

const tooltipStyles = css`
  cursor: auto;
  user-select: none;
`;
