import { Box, Stack, Typography } from "@mui/material";
import { SerializedStyles, css } from "@emotion/react";
import { poppinsFontFamily } from "../../../config/styles";
import { tableHeaderBgColor, white } from "../../../config/theme";
import leagueCaptainIcon from "../../../assets/mini-league/league-captains-icon.svg";
import mostHurtfulIcon from "../../../assets/mini-league/most-hurtful-players-icon.png";
import {
  AppCard,
  AppCardSkeleton,
  Button,
  CardChip,
  CardChipSkeleton,
  Player,
  PlayerSkelton,
  Skeleton,
} from "../../../components";
import { MINI_LEAGUE_PAGE } from "../../../constants/mini-league.constants";

interface IAppCard {
  accentColorRGB?: string;
  iconImage: string;
  cardStyles?: SerializedStyles;
  rank: number;
  player: {
    name: {
      first_name: string;
      last_name: string;
      web_name: string;
    };
    count: number;
    ownedPercentage: string;
  };
  isMostHurtful?: boolean;
  onBtnClick?(): void;
  hideChip?: boolean;
}

/**
 * TopPlayerCard component displays a card with most captained player in a league.
 * It includes information such as rank, captain details, and ownership percentage.
 */

export const TopPlayerCard = (props: IAppCard) => {
  const { name, count, ownedPercentage } = props.player;

  return (
    <AppCard accentColor={`rgb(${props.accentColorRGB})`}>
      <Box css={rootStyle}>
        <Stack css={textContainerStyles}>
          {/* Card Chip */}
          {!props.hideChip && (
            <CardChip
              image={props.isMostHurtful ? mostHurtfulIcon : leagueCaptainIcon}
              title={
                props.isMostHurtful
                  ? MINI_LEAGUE_PAGE.MOST_HURTFUL.CARD.TITLE
                  : MINI_LEAGUE_PAGE.CAPTAINS.CARD.TITLE
              }
            />
          )}

          {/* Highlight title  && Manger name && Description */}
          <Box css={titles}>
            <Typography variant="h1">#{props.rank}</Typography>

            {/* Manger Name if a manger meets the highlight */}
            <Typography css={teamNameStyle(props.accentColorRGB!)} variant="h2">
              {name.first_name} {name.last_name}
            </Typography>

            {/* Description || Comment */}
            <Typography variant="h3">
              {props.isMostHurtful
                ? MINI_LEAGUE_PAGE.MOST_HURTFUL.CARD.MOST_HURTFUL_DESCRIPTION(
                    ownedPercentage,
                    count
                  )
                : MINI_LEAGUE_PAGE.MOST_HURTFUL.CARD.CAPTAIN_DESCRIPTION(
                    ownedPercentage,
                    count
                  )}
            </Typography>
          </Box>

          {props.isMostHurtful && (
            <Button
              button="gradient"
              css={btnStyles}
              onClick={props.onBtnClick}
            >
              {MINI_LEAGUE_PAGE.MOST_HURTFUL.CARD.BTN}
            </Button>
          )}
        </Stack>

        <Box css={imageContainer(props.accentColorRGB)}>
          <Player
            playerShirt={props.iconImage}
            name={name.web_name}
            fieldPosition="xss"
            points={ownedPercentage}
          />
        </Box>
      </Box>
    </AppCard>
  );
};

/** Loading skeleton for top player card */
export const TopPlayerCardSkeleton = () => {
  return (
    <AppCardSkeleton>
      <Box css={rootStyle}>
        <Stack css={textContainerStyles}>
          <CardChipSkeleton />

          <Box css={titles}>
            <Skeleton width={"25%"} sx={{ mb: "0.2rem" }} />

            <Skeleton css={teamNameStyle()} sx={{ mb: "0.2rem" }} />

            <Skeleton width={"80%"} sx={{ mb: "0.2rem" }} />
            <Skeleton width={"50%"} />
          </Box>
        </Stack>

        <Box css={[imageContainer(), imageContainerSkeletonStyle]}>
          <PlayerSkelton />
        </Box>
      </Box>
    </AppCardSkeleton>
  );
};

const rootStyle = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
`;

const textContainerStyles = css`
  position: relative;
  z-index: 10;
  align-items: start;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  max-width: 50%;
`;

const titles = css`
  width: 100%;
  & > * {
    margin-bottom: 0.5rem;
  }

  h1 {
    ${poppinsFontFamily}
    color: ${white};
    font-size: clamp(1rem, 2.7vw, 1.85rem);
    font-weight: 900;
  }

  h3 {
    color: ${white};
    font-size: 0.75rem;
    font-size: clamp(0.85rem, 1.5vw, 1.25rem);
    font-weight: 600;
  }
`;

const teamNameStyle = (accentColor?: string) => css`
  ${poppinsFontFamily}
  color: #96edf4;
  color: rgb(${accentColor});
  font-size: clamp(1.25rem, 3.3vw, 2.6rem);
  font-weight: 800;
  margin-bottom: 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
`;

const imageContainer = (accentColorRGB?: string) => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -1rem;

  height: 65%;
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: 999999px;
  display: flex;
  align-items: center;
  justify-content: center;

  --spread: clamp(1.3rem, 3.5vw, 2.25rem);

  box-shadow: 0 0 0 var(--spread) rgba(${accentColorRGB}, 0.5),
    0 0 0 calc(2 * var(--spread)) #ffffff43;
`;

const imageContainerSkeletonStyle = css`
  background-color: #00000039;
  box-shadow: 0 0 0 var(--spread) ${tableHeaderBgColor},
    0 0 0 calc(2 * var(--spread)) #00000014;
`;

const btnStyles = css`
  padding: 0.5rem clamp(0.5rem, 1vw, 1rem);
  line-height: 1;
  width: fit-content;
`;
