import hide from "../assets/icons/hide.svg";
import { css } from "@emotion/react";
import React, { useState } from "react";
import { Field } from "formik";
import { bgColor } from "../config/theme";
import { ErrorMessage } from "./ErrorMessage";
import { IconButton, Stack } from "@mui/material";

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon?: React.ReactNode;
  name: string;
}

/**
 * This component renders a customizable input field with optional icon and password toggling functionality.
 * It utilizes the `Field` component from `formik` for form integration and the `ErrorMessage` component (assumed to be defined elsewhere) for error display.
 *
 * @param {IInput} props - The component props.
 * @param {React.ReactNode} [props.Icon] - An optional React node to display an icon inside the input field.
 * @param {string} props.type - The type of input field (e.g., "text", "email", "password").
 * @param {string} props.name - The name of the underlying `Field` component, used for form handling and error association.
 * @param {...React.InputHTMLAttributes<HTMLInputElement>} props - Any other valid HTML input element attributes can be passed here.
 *
 * @returns {JSX.Element} - Renders a styled input field with icon (if provided) and optional password toggle functionality.
 */

export const Input = ({ Icon, type, name, ...props }: IInput) => {
  const [fieldType, setFieldType] = useState(type);

  const hidePassword = () => {
    if (fieldType === "password") {
      setFieldType("text");
    } else {
      setFieldType("password");
    }
  };

  return (
    <div css={root}>
      <div css={inputContainer}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          {Icon}
        </Stack>
        <Field css={inputStyle} type={fieldType} name={name} {...props} />
        {type === "password" ? (
          <IconButton onClick={hidePassword}>
            <img src={hide} alt="hide password" />
          </IconButton>
        ) : null}
      </div>
      <ErrorMessage name={name} />
    </div>
  );
};

const root = css``;

const inputContainer = css`
  background-color: ${bgColor};
  border-radius: 1.2rem;
  padding: 0rem 1.5rem; /** block padding defined on input */
  overflow: hidden;
  width: 100%;
  display: flex;
  gap: 1rem;
`;

const inputStyle = css`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0rem;
  background-color: ${bgColor};
  color: white;
  outline: none;
  border: none;
  &::placeholder {
    color: #65656b;
  }
`;
