import { Stack, Typography } from "@mui/material";
import MyTeamSummaryImage from "../../../assets/home/my-team-summary.svg";
import { css } from "@emotion/react";
import { Button, Skeleton } from "../../../components";
import {
  cardBorderRadius,
  homeCardBgGradient,
  mq,
  title,
} from "../../../config/styles";
import { bgColorDark, secondaryColor } from "../../../config/theme";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { useGetTeamInfo } from "../../../utils/hooks/useGetTeamInfo";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";
import { MY_TEAM_SUMMARY } from "../../../constants/home-page.constants";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { useUserContext } from "../../../context/UserContext";

/** Render the fplManger team data section in the home page*/
export const MyTeamSummary = () => {
  const { teamInfo } = useGetTeamInfo();
  const [searchParams] = useSearchParams();
  const captain =
    teamInfo && teamInfo.picks.find((player) => player.is_captain);
  const { getTeamViewPath } = useGetUrl();
  const { user } = useUserContext();
  return (
    <Stack css={myTeamContainer}>
      {teamInfo ? (
        <Stack css={myTeamDataContainer}>
          <Typography variant="h1" css={myTeamTitle}>
            {MY_TEAM_SUMMARY.TITLE}
            {searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)}
          </Typography>
          <Typography variant="h2" css={myTeamName}>
            {teamInfo.name}
          </Typography>

          <Typography variant="h3" css={myTeamPoints}>
            {teamInfo.entry_history.players_points} {MY_TEAM_SUMMARY.POINTS}
          </Typography>

          <Typography css={myTeamCaptainStyle}>
            <span>
              {MY_TEAM_SUMMARY.CAPTAIN.TITLE} {captain?.name}
            </span>
            <span>
              {MY_TEAM_SUMMARY.CAPTAIN.POINTS}{" "}
              {captain!.gw_total_points * captain!.multiplier}{" "}
              {MY_TEAM_SUMMARY.POINTS}
            </span>
          </Typography>

          <Button
            css={myTeamButtonStyle}
            button="solid"
            to={getTeamViewPath(user?.teamId!)}
          >
            {MY_TEAM_SUMMARY.BTN_TITLE}
            <DoubleArrowIcon />
          </Button>
        </Stack>
      ) : (
        <MyTeamSummarySkeleton />
      )}

      <img
        src={MyTeamSummaryImage}
        css={myTeamImageStyle}
        alt="my team summary"
      />
    </Stack>
  );
};

const MyTeamSummarySkeleton = () => {
  return (
    <Stack css={myTeamDataContainer}>
      <Typography variant="h1" css={myTeamTitle}>
        My Team | GW18
      </Typography>
      <Typography variant="h2" css={myTeamName}>
        <Skeleton css={skeletonStyles} width={"50%"} />
      </Typography>

      <Typography variant="h3" css={myTeamPoints} width={"30%"}>
        <Skeleton css={skeletonStyles} />
      </Typography>

      <Typography css={myTeamCaptainStyle}>
        <Skeleton css={skeletonStyles} width={"60%"} />
        <Skeleton css={skeletonStyles} width={"50%"} />
      </Typography>

      <Skeleton css={skeletonStyles} width={"70%"} height={"2.5rem"} />
    </Stack>
  );
};

const myTeamContainer = css`
  ${homeCardBgGradient}
  ${cardBorderRadius}
  flex-direction: row;

  ${mq["sm"]} {
    height: 13rem;
  }

  ${mq["md"]} {
    height: 17.5rem;
  }

  overflow: hidden;
`;

const myTeamImageStyle = css`
  width: fit-content;
  padding: 3rem 0;
`;

const myTeamDataContainer = css`
  flex: 1;
  padding: 1rem;
  gap: 0.5rem;
`;

const myTeamTitle = css`
  ${title}
`;

const myTeamName = css`
  font-size: clamp(0.875rem, 2vw, 1rem);
  font-weight: 700;
`;

const myTeamPoints = css`
  ${title}
  color: ${secondaryColor};

  margin-bottom: 1rem;
`;

const myTeamCaptainStyle = css`
  display: flex;
  flex-direction: column;
  font-size: clamp(0.875rem, 2vw, 1rem);
`;

const myTeamButtonStyle = css`
  padding: 0rem;
  width: 7rem;
`;

const skeletonStyles = css`
  background-color: ${bgColorDark};
`;
