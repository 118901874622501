import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { css } from "@emotion/react";
import { bgColor, primaryColor, secondaryColor, white } from "../config/theme";
import { CupIcon } from "./icons";
import rankNoChangeIcon from "../assets/icons/league-no-rank-change.svg";
import { animatePulse, mq, transition_all } from "../config/styles";
import { useUserContext } from "../context/UserContext";
import { LeagueRankChangeIcon } from "./icons/LeagueRankChangeIcon";
import {
  getLeagueById,
  setFavoriteLeagueInUserMetaData,
} from "../utils/general.helpers";
import { ErrorModal } from ".";
import { MODAL_ERROR_MESSAGES } from "../constants/global.constants";
import { useGlobalInfoContext } from "../context/GlobalInfoContext";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useEffect, useState } from "react";
import { SELECT_LEAGUE_DROPDOWN_CONTENT } from "../constants/global.constants";
import { SESSION_STORAGE } from "../config/app.config";
import { useLocation } from "react-router-dom";
import { ENABLED_MORE_THAN_500 } from "../utils/regexExpressions";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

/* CONSTANTS */
const MAX_LEAGUE_SIZE = 500;

/**
 * SelectLeagueDropDown component renders a dropdown menu for selecting a league.
 * It retrieves data and state from the context using `useUserContext` and `useModalContext`.
 */
export const SelectLeagueDropDown = () => {
  const { user, setUser } = useUserContext();
  const { selectedLeague, leagues, setSelectedLeagueIdUrl } =
    useGlobalInfoContext();
  const [isMoreThan500ManagerModalOpen, setIsMoreThan500ManagerModalOpen] =
    useState(false);
  const { pathname } = useLocation();

  const handleChange = async (event: SelectChangeEvent) => {
    const league = getLeagueById(leagues, event.target.value);

    if (!league) return;

    const errorCheck =
      league.rank_count > MAX_LEAGUE_SIZE &&
      !ENABLED_MORE_THAN_500.test(pathname);
    if (errorCheck) return setIsMoreThan500ManagerModalOpen(true);

    // user from session
    if (user && sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)) {
      sessionStorage.setItem(
        SESSION_STORAGE.TEMP_SELECTED_LEAGUE_ID,
        String(league.id)
      );
      setUser({
        ...user,
        teamId: Number(sessionStorage.getItem(SESSION_STORAGE.TEMP_TEAM_ID)),
        selectedLeagueId: String(league.id),
      });
    }
    setSelectedLeagueIdUrl(String(league.id));
  };

  const handleFavoriteLeagueClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    favLeagueId: string | number
  ) => {
    e.stopPropagation();
    const newUser = await setFavoriteLeagueInUserMetaData(String(favLeagueId));
    setUser(newUser);
  };

  const handleCloseClick = () => {
    setIsMoreThan500ManagerModalOpen(false);
  };

  useEffect(() => {
    if (
      selectedLeague &&
      selectedLeague.rank_count > MAX_LEAGUE_SIZE &&
      !ENABLED_MORE_THAN_500.test(pathname)
    ) {
      (async () => {
        setSelectedLeagueIdUrl(String(leagues[0].id));
        setIsMoreThan500ManagerModalOpen(true);
      })();
    }
  }, [pathname]);

  return (
    <>
      <ErrorModal
        message={MODAL_ERROR_MESSAGES.SELECT_LEAGUE_WITH_MORE_THAN_500_ENTRY}
        isOpen={isMoreThan500ManagerModalOpen}
        onCloseClick={handleCloseClick}
      />
      {leagues && selectedLeague ? (
        <Select
          startAdornment={<CupIcon style={{ width: 55 }} />}
          endAdornment={<ExpandMoreRoundedIcon />}
          IconComponent={() => null}
          css={selectMenuStyle}
          MenuProps={{
            sx: {
              ".MuiList-root": {
                p: "0",
              },
              ".MuiPaper-root": {
                mt: "1rem",
                border: "#dddedf 2px solid",
                borderRadius: "0.8rem",
                backgroundColor: bgColor,
              },
            },
          }}
          renderValue={() => selectedLeague.name}
          value={`${selectedLeague.id}`}
          onChange={handleChange}
        >
          {leagues.map((item, index) => (
            <MenuItem key={index} css={menuItemStyle} divider value={item.id}>
              <Stack css={menuItemContainerStyles}>
                <Stack css={menuItemStyleInfoStyles}>
                  <LeagueRankChange rank={item.rank_change.change_count} />
                  <span>{item.entry_rank.toLocaleString()}</span>
                  {item.name}
                </Stack>
                <IconButton
                  onClick={(e) => handleFavoriteLeagueClick(e, item.id)}
                  css={favoriteLeagueIconStyles}
                >
                  {user?.favoriteLeagueId === String(item.id) ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderIcon sx={{ opacity: 0.5 }} />
                  )}
                </IconButton>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Stack css={skeletonStyle} direction={"row"} alignItems={"center"}>
          <CircularProgress color="secondary" size={30} />
          <Typography sx={{ flex: 1 }}>
            {SELECT_LEAGUE_DROPDOWN_CONTENT.LOADING_TEXT}
          </Typography>
          <ExpandMoreRoundedIcon />
        </Stack>
      )}
    </>
  );
};

/** Renders the change in rank icon in the league DropDown */
const LeagueRankChange = (props: { rank: number }) => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ width: "1rem" }}
    >
      {props.rank === 0 ? (
        <img src={rankNoChangeIcon} />
      ) : props.rank > 0 ? (
        <LeagueRankChangeIcon />
      ) : (
        <LeagueRankChangeIcon fill="#E2422C" style={{ rotate: "180deg" }} />
      )}
    </Stack>
  );
};

const skeletonStyle = css`
  ${animatePulse}
  --spacing: 0.7rem;
  height: 3.7rem;
  gap: var(--spacing);
  width: 100%;
  background-color: ${bgColor};
  border: #dddedf 2px solid;
  border-radius: 0.8rem;
  color: ${white};
  padding: 0 var(--spacing) 0 var(--spacing);

  ${mq["sm"]} {
    max-width: 22.4375rem;
  }
`;

const selectMenuStyle = css`
  --spacing: 0.7rem;
  gap: var(--spacing);
  height: 3.7rem;
  width: 100%;
  background-color: ${bgColor};
  border: #dddedf 2px solid;
  border-radius: 0.8rem;
  color: ${white};
  padding: 0 var(--spacing) 0 var(--spacing);

  ${mq["sm"]} {
    max-width: 22.4375rem;
  }

  &.Mui-focused {
    outline: none;
    box-shadow: none;
  }

  .MuiSelect-select > * {
    display: none;
  }

  svg:last-of-type {
    ${transition_all}
  }

  .MuiSelect-select[aria-expanded="true"] ~ svg:last-of-type {
    transform: rotate(180deg);
  }
`;

const menuItemStyle = css`
  color: white;
  background-color: ${bgColor};
  padding: 0.75rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 1px;
    border-radius: 99999px;
    background-color: #8e8e8e;
  }
  &:last-of-type:after {
    display: none;
  }

  &.MuiPaper-root {
    background-color: #000 !important;
  }

  &.MuiPaper-root.MuiList-root {
    background-color: #000 !important;
  }

  &.Mui-selected {
    background-color: ${primaryColor};
  }

  :hover,
  &.Mui-focusVisible {
    background-color: ${primaryColor};
  }

  &.Mui-selected:hover,
  &.Mui-selected.Mui-focusVisible {
    background-color: ${primaryColor}99;
  }
`;

const menuItemContainerStyles = css`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const menuItemStyleInfoStyles = css`
  flex-direction: row;
  gap: 0.3rem;
`;

const favoriteLeagueIconStyles = css`
  color: ${secondaryColor};
`;
