import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { FOOTER, SOCIAL_MEDIA_LINKS } from "../constants/global.constants";
import { COMING_SOON_PAGE } from "../constants/coming-soon-page-constants/comingSoon.constants";
import X_SOCIAL_PLATFORM from "../assets/icons/x.svg";
import reddit from "../assets/icons/reddit.svg";
import { ROUTES } from "../config/routes";
import { white } from "../config/theme";

/** Renders the app footer section */
export const Footer = () => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} css={footerStyle}>
      <Stack direction={"row"} gap={3}>
        <Link target="_blank" to={SOCIAL_MEDIA_LINKS.TWITTER}>
          <img src={X_SOCIAL_PLATFORM} alt="twitter" />
        </Link>
        <Link target="_blank" to={SOCIAL_MEDIA_LINKS.REDDIT}>
          <img src={reddit} alt="reddit" />
        </Link>
      </Stack>
      <p>{COMING_SOON_PAGE.FOLLOW_US_ON_SOCIAL_MEDIA}</p>
      <p>{COMING_SOON_PAGE.COPY_RIGHT}</p>

      <Stack css={privacyPolicyContainerStyles}>
        <Link to={ROUTES.PRIVACY_POLICY_PAGE}>{FOOTER.PRIVACY_POLICY}</Link>
        <Link to={ROUTES.TERMS_OF_SERVES_PAGE}>{FOOTER.TERMS_OF_SERVICE}</Link>
      </Stack>
    </Stack>
  );
};

const footerStyle = css`
  font-weight: 200;

  p {
    text-align: center;
  }
`;

const privacyPolicyContainerStyles = css`
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  & > * {
    color: ${white};
    text-decoration: underline;
  }
`;
