import { ENV_OPTIONS } from "./src/config/app.config";

/**
 * Initializes Google Analytics
 * This script is add in prod only
 *
 * This script dynamically loads the Google Analytics script and configures it.
 *
 * It checks if the environment is set to "production" and if true,
 * it creates a script element to load the Google Analytics script.
 * Once the script is loaded, it initializes Google Analytics with the provided key.
 */
(function () {
  if ((import.meta as any).env.VITE_ENVIRONMENT === ENV_OPTIONS.PROD) {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${
      (import.meta as any).env.VITE_GOOGLE_ANALYTICS_KEY
    }`;
    document.head.appendChild(script);

    script.onload = () => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        // @ts-ignore
        dataLayer.push(arguments);
      }

      // @ts-ignore
      gtag("js", new Date());
      // @ts-ignore
      gtag("config", (import.meta as any).env.VITE_GOOGLE_ANALYTICS_KEY);
    };
  }
})();
