export interface miniLeagueURLParams {
  teamId: string | number;
  leagueId: string | number;
  history?: boolean;
  gw: string | number;
  phase: number | string;
  pageNumber: number | string;
}

export const FPL_CHAMP_ENDPOINTS = {
  VALIDATE_TEAM_ID: (team_id: string) =>
    `/teams/validate-team-id?team_id=${team_id}`,
  GET_LEAGUES: (teamId: string) => `/teams/${teamId}/leagues`,
  ALL_HIGHLIGHTS: (gameWeek: number | string, leagueId: number) =>
    `/highlights?gw=${gameWeek}&league=${leagueId}`,
  HIGHLIGHTS_INFO: `/highlights/info`,
  GLOBAL_INFO: `/highlights/global-info`,
  CATEGORIES: `/highlights/categories`,
  SUBSCRIBE_TO_EMAIL_MARKETING: `/marketing/subscribe-to-email-marketing`,
  GET_TEAM_ID_BY_TEAM_NAME: (teamName: string) =>
    `/teams/get-team-id-by-team-name?teamName=${teamName}`,
  TEAM_DATA_URL: (teamId: string | number, gameWeek: string | number) =>
    `/teams/${teamId}?gw=${gameWeek}`,
  MINI_LEAGUE: ({
    leagueId,
    gw,
    teamId,
    phase = 1,
    pageNumber = 1,
    history = false,
  }: miniLeagueURLParams) =>
    `/leagues/${leagueId}/standings?gw=${gw}&teamId=${teamId}&phase=${phase}&pageNumber=${pageNumber}&history=${history}`,
  MINI_LEAGUE_CAPTAINS: (leagueId: string | number, gw: string | number) =>
    `/leagues/${leagueId}/captains?gw=${gw}`,

  MINI_LEAGUE_MOST_HURTFUL: (
    leagueId: string | number,
    gw: string | number,
    managerId: string | number
  ) => `/leagues/${leagueId}/hurtful-players?gw=${gw}&entry=${managerId}`,

  MINI_LEAGUE_NEW_ENTRIES: (
    leagueId: string | number,
    pageNumber: string | number
  ) => `/leagues/${leagueId}/new-entries?pageNumber=${pageNumber}`,

  FIXTURES: (
    leagueId: string | number,
    teamId: string | number,
    gw: string | number,
    fgw: string | number
  ) => `/fixtures?entry=${teamId}&league=${leagueId}&gw=${gw}&fgw=${fgw}`,

  PLAYER_OWNERSHIP_FIXTURE: (
    leagueId: string | number,
    fixtureId: string | number,
    gw: string | number,
    fgw: string | number
  ) => `/fixtures/${fixtureId}?league=${leagueId}&gw=${gw}&fgw=${fgw}`,
} as const;
