import { useState } from "react";
import { Swiper, SwiperSlide, SwiperClass, SwiperProps } from "swiper/react";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { css } from "@emotion/react";
import { Box, IconButton, Stack } from "@mui/material";
import { Navigation, Pagination } from "swiper/modules";
import { mq, transition_all } from "../config/styles";
import { PrimitiveAtom, getDefaultStore } from "jotai";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { secondaryColor } from "../config/theme";

interface Carousel extends SwiperProps {
  children: React.ReactNode[];
  carouselAtom?: PrimitiveAtom<number>;
  showBullets?: boolean;
  hideArrows?: boolean;
  accentColor?: string;
}

/**
 * Render a Carousel competent for the highlights card
 * Hides left arrow at being and right arrow at the end
 * Pass a JOTAI Atom to {carouselAtom} to remember the last slide that was scrolled to between navigation
 */

export const Carousel = ({
  accentColor,
  hideArrows,
  showBullets,
  carouselAtom,
  children,
  ...props
}: Carousel) => {
  const [hidden, setHidden] = useState<"start" | "" | "end">("start");
  const defaultStore = getDefaultStore();

  const setSlideNumber = (index: number) => {
    if (carouselAtom) {
      defaultStore.set(carouselAtom, index);
    }
  };

  const handleSlideChange = (swiper: SwiperClass) => {
    setSlideNumber(swiper.realIndex);

    if (swiper.isBeginning) {
      setHidden("start");
    } else if (swiper.isEnd) {
      setHidden("end");
    } else {
      setHidden("");
    }
  };

  const initialSlide = carouselAtom ? defaultStore.get(carouselAtom) : 0;

  const handleNextClick = (swiper: SwiperClass) => {
    setSlideNumber(swiper.realIndex);
  };

  const handlePrevClick = (swiper: SwiperClass) => {
    setSlideNumber(swiper.realIndex);
  };

  return (
    <Swiper
      initialSlide={initialSlide}
      onSlideChange={handleSlideChange}
      onNavigationNext={handleNextClick}
      onNavigationPrev={handlePrevClick}
      css={carouselStyle}
      spaceBetween={25}
      modules={[Navigation, Pagination]}
      pagination={{
        clickable: true,
        el: ".bullet-container",
        // bulletClass: ".bullet",
      }}
      navigation={{
        nextEl: ".button-next",
        prevEl: ".button-prev",
      }}
      {...props}
    >
      {children.map((child, index) => (
        <SwiperSlide key={index}>
          <Box css={slidesContainerStyle(hideArrows!)}>{child}</Box>
        </SwiperSlide>
      ))}

      <IconButton
        className="button-next"
        css={carouselNavButtonIconStyle(hidden === "end" || hideArrows!)}
      >
        <ArrowForwardRoundedIcon />
      </IconButton>
      <IconButton
        className="button-prev"
        css={carouselNavButtonIconStyle(hidden === "start" || hideArrows!)}
      >
        <ArrowForwardRoundedIcon />
      </IconButton>

      {showBullets && (
        <Stack className="bullet-container" css={bulletsStyles(accentColor)} />
      )}
    </Swiper>
  );
};

const slidesContainerStyle = (hideArrows: Boolean) => css`
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  /* padding: 0 0.7rem; */

  ${hideArrows &&
  css`
    padding: 0;
  `}

  margin: 0 auto 0 auto;
`;

const carouselStyle = css`
  width: 100%;
  position: relative;

  .swiper-slide {
    height: auto; // fill height
  }
`;

const carouselNavButtonIconStyle = (isHidden: boolean) => css`
  ${transition_all}
  color: black;
  background-color: white;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  z-index: 999;
  zoom: 0.8;

  &:last-of-type {
    transform: translateY(50%);
    left: 0.5rem;
    right: auto;
    rotate: 180deg;
  }

  &:hover {
    background-color: white;
  }

  ${isHidden &&
  css`
    opacity: 0;
    visibility: hidden;
  `}

  ${mq["md"]} {
    zoom: 0.9;
  }

  ${mq["lg"]} {
    zoom: 1;
  }
`;

const bulletsStyles = (accentColor = secondaryColor) => css`
  flex-direction: row;
  height: 2rem !important;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  /* Position inside Cars */
  /* 
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: fit-content !important; 
  */

  & > * {
    background: ${accentColor};
    cursor: pointer;
    width: auto;
    height: clamp(0.875rem, 2vw, 1rem);
    aspect-ratio: 1/1;
  }
`;
