import { SyntheticEvent } from "react";
import { Tab, Tabs } from "@mui/material";
import { css } from "@emotion/react";
import { transition_all } from "../config/styles";
import { secondaryBgColor } from "../config/theme";
import { Link } from "react-router-dom";

interface IAppTabs {
  value: string | undefined;
  tabs: { label: string; value: string; img: string }[] | undefined;
  isLinkTab?: boolean;
  handleTabsChange?: (
    event: SyntheticEvent<Element, Event>,
    value: any
  ) => void;
}

/** Component the implement the default styles of the Tabs in the app */
export const AppTabs = ({ isLinkTab = false, ...props }: IAppTabs) => {
  const TabComp = isLinkTab ? Link : undefined;
  return (
    <Tabs
      css={[tabsStyle, !props.tabs && loadingStyles]}
      value={props.value || false} // Allow undefined to be selected
      onChange={props.handleTabsChange}
    >
      {props.tabs ? (
        props.tabs.map((tab, index) => (
          <Tab
            LinkComponent={TabComp}
            key={index}
            label={tab.label}
            value={tab.value}
            icon={<img src={tab.img} alt={tab.label} />}
          />
        ))
      ) : (
        <AppTabsSkeleton />
      )}
    </Tabs>
  );
};

const AppTabsSkeleton = () => {
  return (
    <>
      {Array.from({ length: 3 }, (_, index) => (
        <Tab
          sx={{ height: "2.5rem", maxWidth: "6rem", borderRadius: "99999px" }}
          key={index}
        />
      ))}
    </>
  );
};

const loadingStyles = css`
  .MuiButtonBase-root {
    width: 6rem;
    background-color: #222232;
  }
`;

const tabsStyle = css`
  .MuiTabs-flexContainer {
    gap: 0.5rem;
    overflow: auto;
  }

  .MuiButtonBase-root {
    ${transition_all}
    color: white !important;
    border-radius: 99999px;

    min-width: auto;
    min-height: auto;
    height: 2.5rem;
    padding: 0 1rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &.Mui-selected {
      gap: 0.3rem;
      background-color: #222232;
    }

    .MuiTab-iconWrapper {
      ${transition_all}
      margin: 0;
      opacity: 0;
      visibility: hidden;
      width: 0;
    }

    &.Mui-selected > .MuiTab-iconWrapper {
      opacity: 100%;
      visibility: visible;
      width: 1.2rem;
      height: 1.2rem;
      object-fit: cover;
    }

    :hover {
      background-color: ${secondaryBgColor};
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
