import { Carousel } from "../../../components";
import { TopPlayerCard, TopPlayerCardSkeleton } from "./TopPlayerCard";
import {
  accentColorBlueRGB,
  accentColorPurpleRGB,
  secondaryColorRGB,
} from "../../../config/theme";
import { IMiniLeagueMostHurtfulRes } from "../../../utils/hooks/useGetMiniLeagueMostHurtful";
import { IMiniLeagueCaptainsTableRes } from "../../../utils/hooks/useGetMiniLeagueCaptainTable";

const colors = [secondaryColorRGB, accentColorBlueRGB, accentColorPurpleRGB];

interface IMiniLeagueCarousel {
  miniLeagueCardData:
    | IMiniLeagueMostHurtfulRes[]
    | IMiniLeagueCaptainsTableRes[];
  isMostHurtful?: boolean;
  onBtnClick?(row: IMiniLeagueMostHurtfulRes): void;
}

/**
 * Mini League player carousel component with loading skeleton
 */
export const MiniLeagueCarousel = (props: IMiniLeagueCarousel) => {
  return (
    <Carousel>
      {!props.miniLeagueCardData
        ? Array.from({ length: 3 }, (_, index) => (
            <TopPlayerCardSkeleton key={index} />
          ))
        : props.miniLeagueCardData.map((player, index) => (
            <TopPlayerCard
              isMostHurtful={props.isMostHurtful}
              key={index}
              rank={index + 1}
              player={{
                name: player.name,
                count: player.count,
                ownedPercentage: player.ownedPercentage,
              }}
              accentColorRGB={colors[index]}
              iconImage={player.shirtLink}
              onBtnClick={() =>
                props.onBtnClick!(player as IMiniLeagueMostHurtfulRes)
              }
            />
          ))}
    </Carousel>
  );
};
