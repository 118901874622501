import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../config/routes";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { useEffect } from "react";

interface IUseGetUrl {
  defaultLeagueId?: string | number;
  selectedGameWeek?: string | number;
}

/**
 * Hook for generating URLs with dynamic parameters and query parameters based on current route state.
 * @param {IUseGetUrl} [props] - Optional object containing parameters to override default or URL-derived values.
 * @param {string|number} [props.defaultLeagueId] - Optional league ID to use instead of the one derived from the route.
 * @param {string|number} [props.selectedGameWeek] - Optional selected game week to set in query parameters.
 * @returns {Object} - An object containing methods to generate URLs based on current state.
 */

export const useGetUrl = (props?: IUseGetUrl) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { liveGameWeek } = useGlobalInfoContext();

  const defaultLeagueId =
    props && props.defaultLeagueId
      ? props.defaultLeagueId
      : params[PATH_PARAMS.LEAGUE_ID]!;

  useEffect(() => {
    if (!searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK) && liveGameWeek) {
      searchParams.set(PATH_PARAMS.SELECTED_GAME_WEEK, String(liveGameWeek));
      setSearchParams(searchParams);
    }
  }, [liveGameWeek]);

  if (props && props.selectedGameWeek) {
    searchParams.set(
      PATH_PARAMS.SELECTED_GAME_WEEK,
      String(props.selectedGameWeek)
    );
    setSearchParams(searchParams);
  }

  const getGameWeekAndPhaseParams = () => {
    if (!searchParams.get(PATH_PARAMS.SELECTED_PHASE)) {
      searchParams.set(PATH_PARAMS.SELECTED_PHASE, "1");
    }
    return searchParams.toString();
  };

  // Generate query parameter string
  const getQueryParamsString = () => {
    return searchParams.toString();
  };

  // Object containing methods to generate URLs with defaultLeagueId and current query parameters
  const getUrl = {
    getHomePath: (leagueId = defaultLeagueId) =>
      `/home/${leagueId}?${getGameWeekAndPhaseParams()}`,

    getHighlightsPath: (customLeagueId: string | number = defaultLeagueId) =>
      `/leagues/${customLeagueId}/highlights?${getQueryParamsString()}`,

    getHighlightsCategoryPath: (categoryId: string) =>
      `/leagues/${defaultLeagueId}/highlights/${categoryId}?${getQueryParamsString()}`,

    getSingleHighlightsPath: (categoryId: string, highlightId: string) =>
      `/leagues/${defaultLeagueId}/highlights/${categoryId}/${highlightId}?${getQueryParamsString()}`,

    getTeamViewPath: (teamId: string | number) =>
      `/leagues/${defaultLeagueId}/team/${teamId}?${getQueryParamsString()}`,

    getFixturesPath: () =>
      `/leagues/${defaultLeagueId}/fixtures?${getQueryParamsString()}`,

    getFixtureResultsPath: (fixtureId: string | number) =>
      `/leagues/${defaultLeagueId}/fixtures/${fixtureId}?${getQueryParamsString()}`,

    getMiniLeagueStandingsPath: () =>
      `/leagues/${defaultLeagueId}?${getGameWeekAndPhaseParams()}`,

    getMiniLeagueCaptainsPath: () =>
      `/leagues/${defaultLeagueId}/captains?${getQueryParamsString()}`,

    getMiniLeagueMostHurtfulPath: () =>
      `/leagues/${defaultLeagueId}/most-hurtful?${getQueryParamsString()}`,

    getMiniLeagueNewEntriesPath: () =>
      `/leagues/${defaultLeagueId}/new-entries?${getQueryParamsString()}`,

    getTransfersPath: () =>
      `/leagues/${defaultLeagueId}/transfers?${getQueryParamsString()}`,
  };

  return getUrl;
};
