import { useQuery } from "react-query";
import { PATH_PARAMS } from "../../config/routes";
import { IFplManager } from "../types/types";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useParams, useSearchParams } from "react-router-dom";
import { useGlobalInfoContext } from "../../context/GlobalInfoContext";
import { useUserContext } from "../../context/UserContext";

export interface IMiniLeagueStanding extends IFplManager {
  captain_name: string;
  captain_pts: number | string;
}

export interface IMiniLeagueStandingResponse {
  standings: IMiniLeagueStanding[];
  has_next: boolean;
}

/** A react query custom hook to fetch and cache mini league standings table data requires a page number as a param */
export const useGetMiniLeagueData = ({
  pageNumber,
}: {
  pageNumber: number;
}) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { liveGameWeek } = useGlobalInfoContext();
  const { user } = useUserContext();

  const queryKey = [
    "useGetMiniLeagueData",
    params[PATH_PARAMS.LEAGUE_ID],
    liveGameWeek,
    searchParams.get(PATH_PARAMS.SELECTED_PHASE),
    pageNumber,
    user?.teamId,
  ];

  const queryFunction = async () => {
    if (
      params[PATH_PARAMS.LEAGUE_ID] &&
      liveGameWeek &&
      searchParams.get(PATH_PARAMS.SELECTED_PHASE) &&
      user &&
      user.teamId
    ) {
      const miniLeagueStandingParams = {
        leagueId: params[PATH_PARAMS.LEAGUE_ID]!,
        gw: liveGameWeek!,
        phase: searchParams.get(PATH_PARAMS.SELECTED_PHASE)!,
        pageNumber,
        teamId: user?.teamId,
      };

      const { data } = await axiosInstance.get<IMiniLeagueStandingResponse>(
        FPL_CHAMP_ENDPOINTS.MINI_LEAGUE(miniLeagueStandingParams)
      );

      return data;
    }
  };

  const { isLoading, data, isFetching } = useQuery({
    queryKey: queryKey,
    queryFn: queryFunction,
  });

  return {
    miniLeagueStandings: data?.standings,
    hasNext: data?.has_next,
    isLoading,
    isFetching,
  } as const;
};
