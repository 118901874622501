import password from "../../../assets/icons/password.svg";
import { Button, ErrorMessage, Input } from "../../../components";
import { Form, Formik } from "formik";
import {
  ResetPasswordPasswordFormType,
  ResetPasswordYupSchema,
} from "../auth.validation";
import { RESET_PASSWORD_PAGE } from "../../../constants/auth-pages.constants";
import { resetPasswordSupabase } from "../utils/authHelpers";
import { handleErrors } from "../../../utils/handleErrors";
import { root } from "../styles";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { SNACKBAR_MESSAGES } from "../../../constants/global.constants";

/** Renders and handle the reset password form */
export const ResetPasswordForm = () => {
  const initialValues = {
    password: "",
    confirm_password: "",
    generalErr: "",
  };

  const { setSnackBarState } = useSnackbarContext();
  const handleSubmit = async (
    value: ResetPasswordPasswordFormType,
    { setSubmitting, setErrors }: any
  ) => {
    try {
      setSubmitting(true);
      await resetPasswordSupabase(value);

      setSnackBarState((prev) => ({
        ...prev,
        isOpen: true,
        message: SNACKBAR_MESSAGES.PASSWORD_RESET,
      }));

      setSubmitting(false);
    } catch (e) {
      const err = handleErrors(e);
      setErrors({ generalErr: err.message });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordYupSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form css={root(isSubmitting)}>
          <Input
            Icon={<img src={password} />}
            placeholder={RESET_PASSWORD_PAGE.PASSWORD_FIELD_PLACEHOLDER}
            type="password"
            id="password"
            name="password"
          />

          <Input
            Icon={<img src={password} />}
            placeholder={RESET_PASSWORD_PAGE.CONFIRM_PASSWORD_FIELD_PLACEHOLDER}
            type="password"
            id="confirm_password"
            name="confirm_password"
          />

          <ErrorMessage name="generalErr" />
          <Button button="CTA" type="submit" disabled={isSubmitting}>
            {RESET_PASSWORD_PAGE.CTA_BUTTON_TEXT}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
