import { FPL_CHAMP_ENDPOINTS } from "../../../config/fplChampEndpoints.constants";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";
import { axiosInstance } from "../../../config/axios.config";
import {
  IAllHighlights,
  IHighlightCard,
  IHighlightsInfo,
} from "../../../utils/types/types";
import { HIGHLIGHT_IDS } from "../../../constants/highlightsPage.constants";
import { useQuery } from "react-query";
import { useEffect, useRef } from "react";
import { useSnackbarContext } from "../../../context/SnackbarContext";
import { SNACKBAR_MESSAGES } from "../../../constants/global.constants";
import { useSearchParams } from "react-router-dom";
import { PATH_PARAMS } from "../../../config/routes";
import { rollbar, ROLLBAR_USER } from "../../../config/rollbar.config";
import { isAxiosError } from "axios";
import { ENV_OPTIONS } from "../../../config/app.config";

/**
 * fetch the highlights data
 * @return [highlightsCardData, isLoading, isError]
 */
export const useGetHighlights = () => {
  const { selectedLeague, liveGameWeek } = useGlobalInfoContext();
  const [searchParams] = useSearchParams();
  const { snackBarState, setSnackBarState } = useSnackbarContext();
  const counter = useRef(0);

  const getHighlightsData = async () => {
    if (selectedLeague && searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)) {
      const { data: highlightsInfoData } = await axiosInstance.get(
        FPL_CHAMP_ENDPOINTS.HIGHLIGHTS_INFO
      );

      const { data: allHighlights } = await axiosInstance.get(
        FPL_CHAMP_ENDPOINTS.ALL_HIGHLIGHTS(
          searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
          selectedLeague?.id!
        )
      );

      // Must loop over info to account for both views (TOP,BOTTOM)
      let newHighlightsCardData = (highlightsInfoData as IHighlightsInfo[])
        // Merge the highlights info with the all highlights data
        .map((info) => ({
          ...info,
          ...(allHighlights as IAllHighlights[]).find(
            (highlight) => info.highlight_id === highlight.id
          ),
        }))
        .filter(
          // filter out singleHighlightsCardData that doesn't have a details array
          (singleHighlightsCardData) => singleHighlightsCardData.details
        );

      // filter out league rank change if not live qw
      if (
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)! !==
        String(liveGameWeek)
      ) {
        newHighlightsCardData = newHighlightsCardData.filter(
          (item) => item.highlight_id !== HIGHLIGHT_IDS.LEAGUE_RANK_CHANGE
        );
      }

      return newHighlightsCardData as IHighlightCard[];
    }
  };

  const handleErrors = (error: any) => {
    const user = sessionStorage.getItem(ROLLBAR_USER)
      ? JSON.parse(sessionStorage.getItem(ROLLBAR_USER)!)
      : null;

    const errorData = {
      fullPath: window.location.href,
      pathname: window.location.pathname,
      searchParams: window.location.search,
      errorMessage: error.message,
      errorName: error.name,
      errorStack: error.stack,
      ...user,
    };

    if (isAxiosError(error)) {
      if (error.response?.status === 429) {
        rollbar.log("Highlights Too Many Requests Errors", {
          ...errorData,
          errorDetailsFromBe: error.response?.data.error,
        });
      }
    } else {
      rollbar.log(error.message, {
        ...errorData,
      });
    }
  };

  const {
    data: fullHighlightsData,
    isLoading,
    isError,
  } = useQuery({
    useErrorBoundary: false, // by default we catch errors on ErrorBoundary Component
    onError:
      import.meta.env.VITE_ENVIRONMENT === ENV_OPTIONS.PROD
        ? handleErrors
        : undefined,
    queryFn: getHighlightsData,
    queryKey: [
      {
        selectedGameWeek: searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
        selectedLeague,
      },
    ],
  });

  // filter out "Transfers Outcome" and "Transfers" if gameWeek is 1
  let cardsHighlightsData =
    fullHighlightsData &&
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)! === "1"
      ? fullHighlightsData.filter(
          (item) =>
            item.highlight_id !== HIGHLIGHT_IDS.TRANSFERS_OUTCOME &&
            item.highlight_id !== HIGHLIGHT_IDS.TRANSFERS
        )
      : fullHighlightsData;

  useEffect(() => {
    const counterInterval = setInterval(() => {
      if (isLoading) {
        counter.current += 1;
        if (counter.current === 8) {
          setSnackBarState(() => ({
            ...snackBarState,
            isOpen: true,
            message: SNACKBAR_MESSAGES.HIGHLIGHTS_LOADING_8_SEC,
            duration: 7500,
          }));
        } else if (counter.current === 12) {
          setSnackBarState(() => ({
            ...snackBarState,
            isOpen: true,
            message: SNACKBAR_MESSAGES.HIGHLIGHTS_LOADING_12_SEC,
            duration: 100 * 1000,
          }));
        }
      }
    }, 1000);

    if (!isLoading) {
      counter.current = 0;
      clearInterval(counterInterval);
      setSnackBarState(() => ({ ...snackBarState, isOpen: false }));
    }

    return () => {
      clearInterval(counterInterval);
    };
  }, [isLoading]);

  return {
    fullHighlightsData,
    cardsHighlightsData,
    isLoading,
    isError,
  } as const;
};
