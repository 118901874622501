import { useQuery } from "react-query";
import { axiosInstance } from "../../config/axios.config";
import { FPL_CHAMP_ENDPOINTS } from "../../config/fplChampEndpoints.constants";
import { useParams, useSearchParams } from "react-router-dom";
import { PATH_PARAMS, QUERY_PARAMS } from "../../config/routes";
import { useUserContext } from "../../context/UserContext";

export interface IFixturesResponse {
  deadline_time: string;
  fixtures: {
    code: number;
    event: number;
    finished: boolean;
    finished_provisional: boolean;
    id: number;
    kickoff_time: string;
    minutes: number;
    provisional_start_time: boolean;
    started: boolean;
    team_a: number;
    team_a_name: string;
    team_a_score: number | null;
    team_a_logo: string;
    team_h: number;
    team_h_name: string;
    team_h_score: number | null;
    team_h_logo: string;
    stats: {
      identifier: string;
      a: {
        value: number;
        element: number;
      }[];
      h: {
        value: number;
        element: number;
      }[];
    }[];
    modified_stats: {
      identifier: string;
      teamH: {
        epl_player_name: string;
        element: number;
        value: number;
      }[];
      teamA: {
        epl_player_name: string;
        element: number;
        value: number;
      }[];
    }[];
    team_h_difficulty: number;
    team_a_difficulty: number;
    pulse_id: number;
    my_players_count: number;
    captains_count: number;
    hurtful_players_count: number;
    league_players_count: number;
    game_importance: string;
  }[];
}

interface IUseGetFixtures {
  isCurrent?: boolean;
}

/** useQuery Hook to fetch fixtures data from backend */
export const useGetFixtures = (props?: IUseGetFixtures) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useUserContext();

  const fgw = props?.isCurrent
    ? searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)
    : searchParams.get(QUERY_PARAMS.FUTURE_GAME_WEEK);

  const queryKey = [
    "fixtures",
    params[PATH_PARAMS.LEAGUE_ID]!,
    user?.teamId!,
    fgw,
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK),
  ];

  const getFixtures = async (queryKey: (string | number | null)[]) => {
    if (queryKey.some((item) => item === undefined || item === null)) return;

    const { data } = await axiosInstance.get<IFixturesResponse>(
      FPL_CHAMP_ENDPOINTS.FIXTURES(
        params[PATH_PARAMS.LEAGUE_ID]!,
        user!.teamId!,
        searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)!,
        fgw!
      )
    );

    return data;
  };

  const { data: fixturesData } = useQuery({
    queryFn: ({ queryKey }) => getFixtures(queryKey),
    queryKey: queryKey,
  });

  return { fixturesData };
};
