export const FIXTURE_PAGE = {
  META: {
    TITLE: "Fixtures",
  },
  TITLE: "Fixtures",
  FIXTURE_DEADLINE_TITLE: "Transfers Deadline:",
  FIXTURE_CARD: {
    CAPTAINS_CHIP: (captainsCount: number) =>
      `${captainsCount} Captain${captainsCount === 1 ? "" : "s"}`,
    TOOLTIP_TEXT: (captainsCount: number | string, leagueName: string) =>
      `${captainsCount} captains are playing from "${leagueName}"`,
    INFO: (
      playerCount: string | number,
      leagueName: string,
      leaguePlayerCount: number | string
    ) =>
      `You own ${playerCount} players from this fixture, while "${leagueName}" has ${leaguePlayerCount} players from it`,
  },
};

export const FIXTURES_RESULTS = {
  META: {
    TITLE: "Fixtures Results",
  },
  TITLE: "Results",
  HOME_TEAM: "(H)",
  AWAY_TEAM: "(A)",
  FULL_TIME: "Full Time",
  VS: "v.s.",
  TABS: {
    overview: { label: "Overview", value: "overview" },
    playerOwnership: { label: "Player Ownership", value: "player-ownership" },
  },
};
