import { Stack, Typography } from "@mui/material";
import { AppTable, AppTableSkeleton } from "../../../components";
import { css } from "@emotion/react";
import { columnNumber } from "../../../utils/muiTableColumns";
import {
  teamColumn,
  extraColumns,
  liveGWCaptainPointsColumn,
} from "../../../utils/tableColumns/miniLeagueTableColumns";
import { homeCardBgColor, title } from "../../../config/styles";
import { Link, useSearchParams } from "react-router-dom";
import {
  IMiniLeagueStanding,
  useGetMiniLeagueData,
} from "../../../utils/hooks/useGetMiniLeagueData";
import { PATH_PARAMS } from "../../../config/routes";
import { useGetUrl } from "../../../utils/hooks/useGetUrl";
import { MINI_LEAGUE_SUMMARY } from "../../../constants/home-page.constants";
import { useGlobalInfoContext } from "../../../context/GlobalInfoContext";
import { MiniLeagueSummaryErrorCard } from "./MiniLeagueSummaryErrorCard";
import { useNavigateState } from "../../../utils/hooks/useNavigateState";

const columns = [
  {
    ...columnNumber,
    renderCell: ({ row }: { row: IMiniLeagueStanding }) => row.rank_sort,
  },
  teamColumn,
  ...extraColumns,
  liveGWCaptainPointsColumn,
];

/** Renders the Mini league table in the home page or an error card if game week is not live game week*/
export const MiniLeagueSummary = () => {
  const { miniLeagueStandings } = useGetMiniLeagueData({
    pageNumber: 1,
  });
  const { liveGameWeek } = useGlobalInfoContext();
  const [searchParams] = useSearchParams();
  const { getMiniLeagueStandingsPath, getTeamViewPath } = useGetUrl();
  const navigate = useNavigateState();

  if (
    searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK) !== String(liveGameWeek)
  ) {
    return <MiniLeagueSummaryErrorCard />;
  }

  const handleRowClick = (fplManagerId: string) => {
    navigate(getTeamViewPath(fplManagerId));
  };

  return (
    <div>
      <Stack css={tableTitleContainerStyle}>
        <Typography css={tableTitleStyle}>
          {MINI_LEAGUE_SUMMARY.TITLE}
          {searchParams.get(PATH_PARAMS.SELECTED_GAME_WEEK)}
        </Typography>

        <Link to={getMiniLeagueStandingsPath()}>
          {MINI_LEAGUE_SUMMARY.LINK_TITLE}
        </Link>
      </Stack>

      {miniLeagueStandings ? (
        <AppTable
          getRowHeight={() => "auto"}
          pageSize={51}
          css={fixturesGroupContainerStyles}
          hideFooter
          onRowClick={({ row }) => handleRowClick(row.entry)}
          columns={columns}
          rows={miniLeagueStandings}
        />
      ) : (
        <AppTableSkeleton />
      )}
    </div>
  );
};

const fixturesGroupContainerStyles = css`
  overflow: auto;
  height: calc(50vh + 1rem);
`;

const tableTitleStyle = css`
  ${title}
`;

const tableTitleContainerStyle = css`
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
  ${homeCardBgColor}
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: underline;
    font-weight: 200;
    color: white;
  }
`;
